<template>
  <div>
    <h2>Adicionar regra</h2>
    <v-form @submit.prevent="save()" ref="formCommissionRule">
      <v-row>
        <v-col cols="12" md="3">
          <v-checkbox label="Todas Cidades" v-model="rule.all_cities"></v-checkbox>
        </v-col>
        <v-col cols="12" md="3">
          <v-checkbox label="ICMS" v-model="rule.icms"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :items="partners"
            label="Parceira"
            item-text="name"
            item-value="id"
            v-model="rule.partner_id"
            :rules="[v => !!v||'Este campo é obrigatório']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            :items="cities"
            label="Cidade"
            item-text="name"
            item-value="id"
            v-model="rule.city_id"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Porcentagem"
            v-model="rule.percentage"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Mínimo"
            v-model="rule.min_freight"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="3">
          <v-checkbox label="ICMS" v-model="rule.icms"></v-checkbox>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="Taxas" v-model="rule.fees"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="TDA" v-model="rule.tda"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field label="TDE" v-model="rule.tde"></v-text-field>
        </v-col>
      </v-row> -->
      <v-btn color="success" type="submit" :loading="saving">Salvar</v-btn>
    </v-form>
  </div>
</template>

<script>

import http from "@/modules/http";

export default {
  data() {
    return {
      saving: false,
      partners: [],
      cities: [],
      rule: {
        partner_id: "",
        city_id: "",
        all_cities: false,
        percentage: "",
        icms: false,
        fees: "",
        tda: "",
        tde: "",
        min_freight: "",
      },
    };
  },
  mounted() {
    this.getPartners();
    this.getCities();
  },
  methods: {
    getPartners() {
      http
        .get("api/partners")
        .then((res) => {
          this.partners = res.data;
        })
        .catch((err) => {
          this.$side({
            type: "error",
            msg: "Erro de conexão",
            duration: 4000,
          });
        });
    },
    getCities() {
      http
        .get("api/cities")
        .then((res) => {
          this.cities = res.data;
          this.cities.push({id: null, name: 'Nenhuma'});
        })
        .catch((err) => {
          console.log(err);
          this.$side({
            type: "error",
            msg: "Erro de conexão",
            duration: 4000,
          });
        });
    },
    save() {
      if(this.$refs.formCommissionRule.validate()) {
        this.saving = true;
        http
        .post('api/commission-rules', this.rule)
        .then(() => {
          this.saving = false;
          this.$side({
            type: 'success',
            msg: 'Salvo com sucesso',
            duration: 4000
          });
          this.$emit('commissionRuleSaved');
        })
        .catch(err => {
          this.saving = false;
          console.log(err);
          this.$side({
            type: 'error',
            msg: 'Erro ao salvar',
            duration: 4000
          })
        })
  }
    },
  },
};
</script>

<style scoped>
</style>