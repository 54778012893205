<template>
  <div>
    <v-data-table
      :items="commissionRules"
      :headers="headers"
      :loading="loading"
    >
      <template v-slot:[`item.percentage`]="{ item }">
        <span>{{ item.percentage }}%</span>
      </template>

      <template v-slot:[`item.icms`]="{ item }">
        <span>{{ item.icms ? 'Sim' : 'Não' }}</span>
      </template>

      <template v-slot:[`item.all_cities`]="{ item }">
        <span>{{ item.all_cities ? 'Sim' : 'Não' }}</span>
      </template>

      <template v-slot:[`item.fees`]="{ item }">
        <span>{{ item.fees != null ? `${item.fees}%` : '' }}</span>
      </template>

      <template v-slot:[`item.tda`]="{ item }">
        <span>{{ item.tda != null ? `${item.tda}%` : '' }}</span>
      </template>

      <template v-slot:[`item.tde`]="{ item }">
        <span>{{ item.tde != null ? `${item.tde}%` : '' }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon color="warning">mdi-pencil</v-icon> -->
        <v-icon :disabled="loading" @click="deleteCommissionRule(item.id)" color="error">mdi-delete</v-icon>
      </template>

    </v-data-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        commissionRules: [],
        headers: [
          {text: 'ID', value: 'id'},
          {text: 'Parceira', value: 'partners.name'},
          {text: 'Cidade', value: 'cities.name'},
          {text: 'Todas', value: 'all_cities'},
          {text: 'Porcentagem', value: 'percentage'},
          {text: 'ICMS', value: 'icms'},
          {text: 'Taxas', value: 'fees'},
          {text: 'TDA', value: 'tda'},
          {text: 'TDE', value: 'tde'},
          {text: 'Ações', value: 'actions'},
        ]
      }
    },
    mounted () {
      this.getCommissionRules();
    },
    methods: {
      deleteCommissionRule(c) {
        if(confirm('Tem certeza que deseja excluir esta regra?')) {
          this.loading = true;
          this.$http.delete('api/commission-rules/'+c)
            .then(() => {
              this.loading = false;
              this.$side({
                type    : 'success',
                msg     : 'Removido com sucesso',
                duration: 4000
              });
              this.getCommissionRules();
            })
            .catch(err => {
              this.loading = false;
              this.$side({
                type    : 'error',
                msg     : 'Erro de conexão',
                duration: 4000
              })
            })
        }
      },
      getCommissionRules() {
        this.loading = true;
        this.$http.get('api/commission-rules')
          .then(res => {
            this.loading = false;
            this.commissionRules = res.data;
          })
          .catch(err => {
            this.loading = false
            console.log(err);
            this.$side({
              type    : 'error',
              msg     : 'Erro de conexão',
              duration: 4000
            })
          })
      }
    },
  }
</script>

<style scoped>

</style>