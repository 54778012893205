var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.commissionRules,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.percentage)+"%")])]}},{key:"item.icms",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.icms ? 'Sim' : 'Não'))])]}},{key:"item.all_cities",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.all_cities ? 'Sim' : 'Não'))])]}},{key:"item.fees",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.fees != null ? ((item.fees) + "%") : ''))])]}},{key:"item.tda",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tda != null ? ((item.tda) + "%") : ''))])]}},{key:"item.tde",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tde != null ? ((item.tde) + "%") : ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":_vm.loading,"color":"error"},on:{"click":function($event){return _vm.deleteCommissionRule(item.id)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }