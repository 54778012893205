<template>
  <div>
    <h1>Regras de Comissão</h1>

    <div class="d-flex ma-4">
      <v-btn @click="adding = !adding" :color="adding ? 'error' : 'success'">
        <v-icon>{{ adding ? 'mdi-keyboard-return' : 'mdi-plus' }}</v-icon>
      </v-btn>
    </div>

    <div v-if="adding">
      <create-commission-rule @commissionRuleSaved="handleSave()" />
    </div>

    <div v-if="!adding">
      <show-commissions-rule />
    </div>

  </div>
</template>

<script>

import http from '@/modules/http';
import CreateCommissionRule from '../components/CreateCommissionRule.vue';
import ShowCommissionsRule from '../components/ShowCommissionsRule.vue';

  export default {
    components: { 
      CreateCommissionRule,
      ShowCommissionsRule,
    },

    data() {
      return {
        adding: false,
        ctes: [],
        rules: [],
      }
    },
    mounted () {
      this.getCommissionRules();
      http
        .get('api/ctes')
        .then(res => {
          this.ctes = res.data
        })
        .catch(err => {
          console.log(err);
          this.$side({
            type    : 'error',
            msg     : 'Erro ao buscar CTes',
            duration: 4000
          });
        });
    },
    methods: {
      getCommissionRules() {
        http
          .get('api/commission-rules')
          .then(res => {
            this.rules = res.data;
          })
          .catch(err => {
            this.$side({
              type    : 'error',
              msg     : 'Erro de conexão',
              duration: 4000
            });
          });
      },
      handleSave() {
        this.adding = false;
      }
    },
  }
</script>

<style scoped>

</style>